import React, { useState } from 'react'
import chance from 'chance'
import Textarea from '../components/Textarea'

const RandomNumberGenerator = () => {
    const Chance = new chance()

    const [randomNumbers, setRandomNumbers] = useState([])
    const [desiredCount, setDesiredCount] = useState(5)
    const [numberType, setNumberType] = useState(`integer`)
    const [precision, setPrecision] = useState(`50`)
    const [lowerLimit, setLowerLimit] = useState(`-100`)
    const [upperLimit, setUpperLimit] = useState(`100`)

    const generate = e => {
        e.preventDefault()

        const list = []
        let min = parseInt(lowerLimit)
        let max = parseInt(upperLimit)

        if (min > max) min = max - 1

        for (let index = 0; index < desiredCount; index++) {
            const random =
                numberType === `decimal`
                    ? `${Chance.integer({
                          min: min,
                          max: max - 1,
                      })}.${Chance.string({
                          numeric: true,
                          length: parseInt(precision),
                      })}`
                    : `${Chance.integer({ min: min, max: max })}`

            list.push(random)
        }

        setRandomNumbers(list)
    }

    return (
        <div>
            <form className="" onSubmit={generate}>
                <div className="columns">
                    
					<div className="column">
                        <div className="field">
                            <label className="label" htmlFor="desiredCount">
                                How many random numbers should we generate?
                            </label>
                            <div className="control">
                                <input
                                    className="input"
                                    type="number"
                                    id="desiredCount"
                                    min="1"
                                    max="99999"
                                    placeholder="1"
                                    value={desiredCount}
                                    onChange={e =>
                                        setDesiredCount(
                                            parseInt(e.target.value)
                                        )
                                    }
                                />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Number Type</label>

                            <label
                                htmlFor="numberTypeInteger"
                                className="radio"
                            >
                                <input
                                    id="numberTypeInteger"
                                    type="radio"
                                    name="numberType"
                                    value={`integer`}
                                    checked={`integer` === numberType}
                                    onChange={e => setNumberType(`integer`)}
                                />
                                {` `}
                                Integer
                            </label>

                            <label
                                htmlFor="numberTypeDecimal"
                                className="radio"
                            >
                                <input
                                    id="numberTypeDecimal"
                                    type="radio"
                                    name="numberType"
                                    value={`decimal`}
                                    checked={`decimal` === numberType}
                                    onChange={e => setNumberType(`decimal`)}
                                />
                                {` `}
                                Decimal
                            </label>

                        </div>

                        <div
                            className={`field ${
                                `decimal` === numberType ? `` : `hidden`
                            }`}
                        >
                            <label className="label" htmlFor="precision">
                                Precision
                            </label>
                            <input
                                className="input"
                                type="number"
                                id="precision"
                                min="0"
                                max="100"
                                placeholder={precision}
                                value={precision}
                                onChange={e => setPrecision(e.target.value)}
                            />
                        </div>

                        <div className="field">
                            <label className="label" htmlFor="lowerLimit">
                                Lower Limit
                            </label>
                            <input
                                className="input"
                                type="text"
                                pattern="[\-]?([\-,.0-9])+"
                                maxLength="9"
                                id="lowerLimit"
                                placeholder={lowerLimit}
                                value={lowerLimit}
                                onChange={e => setLowerLimit(e.target.value)}
                            />
                        </div>

                        <div className="field">
                            <label className="label" htmlFor="upperLimit">
                                Upper Limit
                            </label>
                            <input
                                className="input"
                                type="text"
                                pattern="[\-,.0-9]+"
                                id="upperLimit"
                                maxLength="10"
                                placeholder={upperLimit}
                                value={upperLimit}
                                onChange={e => setUpperLimit(e.target.value)}
                            />
                        </div>

                        <button
                            type="submit"
                            className="button is-primary"
                        >
                            Generate
                        </button>
                    </div>
							
					<div className="column is-two-thirds">
						<Textarea
							className="textarea"
							style={{
								width: `100%`,
								backgroundColor: `#f9f9f9`,
								padding: `1rem`,
								fontFamily: `monospace`,
							}}
							rows={
								parseInt(desiredCount) > 100
									? 100
									: parseInt(desiredCount) + 1
							}
							value={randomNumbers.join(`\n`)}
							readOnly={true}
						/>
					</div>

				</div>
            </form>
        </div>
    )
}

RandomNumberGenerator.propTypes = {}

RandomNumberGenerator.defaultProps = {}

export default RandomNumberGenerator
