import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import RandomNumberGenerator from '../../tools/RandomNumberGenerator'

const RandomNumberGeneratorPage = () => (
    <Layout>
        <SEO title="Random Number Generator – Generate Random Numbers Online" />

        <section className="hero is-primary">
            <div className="hero-body">
                <div className="container is-fluid">
                    <h1 className="title">Online Random Number Generator</h1>
                    <h2 className="subtitle">
                        Simple tool to generate a list of random numbers between
                        a specific range. You can use this online tool to
                        generate pseudo-random integers and floating-point
                        numbers.
                    </h2>
                </div>
            </div>
        </section>

		<section className="section">
            <div className="container is-fluid">
        		<RandomNumberGenerator />
            </div>
        </section>

    </Layout>
)

export default RandomNumberGeneratorPage
